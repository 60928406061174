import '/assets/styles/sections/home-horizontal-banners.scss';
import PropTypes from 'prop-types';

// Partials
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Placeholder from '/views/partials/placeholder';

const HomeHorizontalBanners = ({ data }) => {
	if(!(data && data.length === 0)) {
		const banners = data ? data : [false, false, false];
		return (
			<section className="section home-horizontalbanners">
				<div className="wrapper horizontalbanners-wrap">
					<ul className="horizontalbanners-list">
						{banners.map((banner, nth) => (
							<li className="list-banner" key={nth}>
								{banner ?
									<Link
										raw
										className={`banner-link ${banner.background_color ? banner.background_color : ''}`}
										href={banner.link}
										target={banner.target}>
										<Img
											cover
											className="banner-image"
											src={banner.image_url} />
										{(banner.title || banner.sub_title) &&
											<div className="banner-text">
												{banner.title &&
													<h3 className="text-title">{banner.title}</h3>
												}
												{banner.sub_title &&
													<strong className="text-subtitle">{banner.sub_title}</strong>
												}
											</div>
										}
									</Link>
									:
									<Placeholder className="banner-link" />
								}
							</li>
						))}
					</ul>
				</div>
			</section>
		)
	}

	return null;
}

HomeHorizontalBanners.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([false])]),
}

export default HomeHorizontalBanners